@mixin ff($size: null, $weight, $line-height: null) {
  //font-family: $family, sans-serif;
  font-weight: $weight;
  @if ($size) {
    font-size: $size;
  }
  @if ($line-height) {
    line-height: $line-height;
  }
}

// usage
// @use "src/styles/mixins/ff" as *;
// .text{
//   @include ff(14px, 600, 17px);
// }
