@import "/src/styles/mixin";

.timer {
  &Items {
    @include font20;
    display: flex;
    gap: 34px;

    @media screen and (max-width: 1023px) {
      gap: 25px;
    }

    @media screen and (max-width: 767px) {
      gap: 11px;
    }
  }

  &ItemContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    &:not(:last-child)::after {
      content: ":";
      font-size: 40px;
      position: absolute;
      right: -23px;
      top: 20px;

      @media screen and (max-width: 1023px) {
        right: -18px;
        top: 12px;
      }

      @media screen and (max-width: 767px) {
        right: -8px;
        top: 2px;
        @include font20;
      }
    }
  }

  &ItemText {
    @include font20;
    font-weight: 600 !important;

    @media screen and (max-width: 767px) {
      @include font16;
    }
  }

  &TimeContainer {
    display: flex;
    gap: 8px;

    @media screen and (max-width: 1023px) {
      gap: 6px;
    }
  }

  &Item {
    width: 60px;
    padding: 0 11px;
    position: relative;
    background-color: var(--white-color);
    border-radius: var(--border-radius-small);
    text-align: center;
    font-size: 60px;
    line-height: 80px;
    font-weight: 600 !important;
    box-shadow: 0 0 6px #4d545a26;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1023px) {
      font-size: 45px;
      line-height: 60px;
      padding: 0 8px;
      width: 44px;
    }

    @media screen and (max-width: 767px) {
      @include font20;
      line-height: 12px !important;
      padding: 10px 8px;
      width: 24px;
    }
  }
}
